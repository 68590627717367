import React, {useEffect, useState, useContext} from 'react'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import axios from 'axios'
import EditButton from '../../../../UI/Dashboard/EditButton';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../../UI/Dashboard/Pagination';
import { toast } from "react-toastify";
import Popup from "../../../../UI/Dashboard/Popup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import Button from "../../../../UI/Form/Button";
import JourneyAjax from "../../../../../helpers/JourneyAjax";

export default function PropertyList() {
    const [listInfo, setListInfo] = useState(null)    
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const location = useLocation()
    const userValidToken = localStorage.getItem("userToken")
    const [open, setOpen] = useState(false);
    const [userToken, setUserToken] = useState(null);
    

    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{
       if(userValidToken){
        const headers = {
            'x-user':userValidToken,
            "x-api-key":process.env.REACT_APP_X_API_KEY
        }
        let response = await axios.get(`${process.env.REACT_APP_JOURNEY_URL}/admin/app-versions${newpage !== false ? `?filter={"limit":10,"skip":${pageParams*10}}` : ''}${pageParams && newpage === false ? `?filter={"limit":10,"skip":${pageParams*10}}` : ''}`,{headers})
        if(response && response.data && response.data.data && response.data.data.apiVersion && response.data.data.apiVersion.length > 0){
            setListInfo(response.data.data.apiVersion)
            setTotalResult(response.data.data.totalCount)
            setTotalRows(response.data.data.totalCount / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
    }else{
        setOpen(true)
    }
        })()
    },100)
    return()=>{
        clearInterval(getResponse)
    }
    },[location,userToken])
    
    const userValid = async (values, event) => {
        const data = {
          ...(values.slug && { slug: values.slug }),
          username: values.username,
        };
    
        const formData = new FormData();
    
        for (var key in data) {
          formData.append(key, data[key]);
        }
    
        const checkUser = await JourneyAjax({
          url: `/admin/${data.username}`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "x-user": data.username,
          token: true,
        });
    
        if (checkUser && checkUser.status === 200) {
          toast.success("User validation successfully");
          setUserToken(checkUser);
          localStorage.setItem("userToken", checkUser.data.username);
          setOpen(false);
        }
    
        if (checkUser && checkUser.status === 403) {
          toast.error("Username is not valid");
        }
      };

    return (
        <>
    {userValidToken ? 
    <ContentLayout title='App config list' addNew='/admin/setting/appConfig'>
        <div className="row">
        <Helmet>
            <title>App config list</title>
        </Helmet>
        <DataView title="App config list">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Andriod Version</td>
                        <td className="text-start">Ios Version</td>
                        <td className="text-center">Edit</td>
                    </tr>
                </thead>
                <tbody>
                    {listInfo == null ? 
                    <TableLoader cols={4}/> : listInfo.length === 0 ? 
                    <tr>
                        <td colSpan={4} className="text-center">Data not available</td>
                    </tr> : listInfo.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.androidAppVersion}</td>
                        <td className="text-start">{data.iosAppVersion}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/setting/appConfig?id=${data.id}`}</EditButton>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/setting/configList' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    :
    <Popup open={open} close={setOpen}>
    <Formik
      initialValues={{
        username: "",
      }}
      onSubmit={(values, event) => {
        userValid(values, event);
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup label="Username" required>
            <Field
              placeholder="Username"
              name="username"
              className="form-control"
            />
            <ErrorField>
              <ErrorMessage name="username" />
            </ErrorField>
          </FormGroup>

          <div className="d-grid">
            <Button type="submit">Enter User ID</Button>
          </div>
        </Form>
      )}
    </Formik>
  </Popup>
}
</>
    )
}
