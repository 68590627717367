import React, { useState, useEffect } from 'react'
import axios from "axios"
import { toast } from 'react-toastify'


export default function HomeScreenQuickLinks({data, target, action, label, value, isProperty, propertyId, slug}) {
    const [title,setTitle] = useState(label)
    const [slugUrl,setSlugUrl] = useState("")
    const [propertyCheck,setPropertyCheck] = useState(isProperty ? true  : false)
    const [propertyList,setPropertyList] = useState([])
    const [selectedProperty,setSelectedProperty] = useState({})
    const [url,setUrl] = useState(value)
    

    const getPropertyList = async (value) =>{
        try{
           document.querySelector(".form-loader").classList.add("show")
           if(value){
               setPropertyCheck(value)
               const requestBody = {}
               requestBody.where = {
                       "active":true,
                       "soldout":0
                   }
               requestBody.include = ["id","developer","unitNumber"]
               requestBody.limit = 200
               const propertyDetail = await axios.post(`${process.env.REACT_APP_ESMS_URL}/buyer-property/feed`,requestBody)
               setPropertyList(propertyDetail.data.data)
           }else{
               setPropertyCheck(value)
               setPropertyList([])
           }
           document.querySelector(".form-loader").classList.remove("show")
       }catch(error){
         console.log(error,"error")
         throw error;
       }
       }
   
       const handleIsProperty = async (id) =>{
           try{
               if(id == ""){
                   toast.error("Select valid property")
                   return;
               }
               document.querySelector(".form-loader").classList.add("show")
                   const requestBody = {}
                   requestBody.where = {
                           id: {
                               inq: [id]
                           },
                           "active":true,
                           "soldout":0
                       }
                   requestBody.include = ["id","name","developer","slug","area","unitNumber","locationId","sector","price","location","bhk","active","soldout","city","state"]
                   const propertyDetail = await axios.post(`${process.env.REACT_APP_ESMS_URL}/buyer-property/feed`,requestBody)
                   if(propertyDetail.data && propertyDetail.data.data){
                   setSelectedProperty(propertyDetail.data.data[0])
                   setSlugUrl(propertyDetail.data.data[0]?.slug)
                   }else{
                       toast.error("Property detail not found")
                       return;
                   }
                   document.querySelector(".form-loader").classList.remove("show")
           }catch(error){
             console.log(error,"error")
             throw error;
           }
           }


    useEffect(()=>{
        (async () => {
            action(current =>{
                current.map((item)=>{
                    if(item.key == target){
                        item.title = title ?? ""
                        item.key = target ?? ""
                        item.url = url || ""
                        item.isProperty = propertyCheck
                        item.slug = slugUrl || ""
                        item.area = selectedProperty?.area || ""
                        item.config = selectedProperty?.bhk?.name || ""
                        item.price = selectedProperty?.price || ""
                        item.propertyId = selectedProperty?.id || ""
                        item.sector = selectedProperty?.sector?.name || ""
                        item.city = selectedProperty?.city?.name || ""
                        item.propertyName = selectedProperty?.name || ""
                        item.state = selectedProperty?.state?.name || ""
                        item.location = selectedProperty?.locationId ? selectedProperty?.locationId?.name : selectedProperty?.location ? selectedProperty?.location : ""
                    }
                    return item
                })
            return current
            })
          })();
    },[selectedProperty,propertyCheck,title,url,slugUrl])

    useEffect(()=>{
        setSlugUrl(slug ?? "")
        if(propertyId){
                handleIsProperty(propertyId)
            }
        if(isProperty){
                getPropertyList(isProperty)
        }
    },[])

    const deleteRow = () => {
        let deleteConfirm = window.confirm("Are you sure want to delete ?")
        if(deleteConfirm === true) {
            action(current =>
                current.filter(data => {
                    return data.key !== target;
                })
            )
        }
    }

    const upload = async (e) => {
        document.querySelector(".form-loader").classList.add("show")
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("file", file);
        const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
        const reqHeaders = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const response = await axios.post(URL, formData, reqHeaders);
        const uploadDocRes = response.data;

        if(uploadDocRes && uploadDocRes.length >0){
           setUrl(uploadDocRes[0])
        }
        document.querySelector(".form-loader").classList.remove("show")
    }

    
   
    return (
        <tr>
            <td className="text-start">
                <input type="hidden" name="key" defaultValue={target ? target : ''} />
                <input type="text" name="slug" defaultValue={title ? title : ''} onChange={(e)=>setTitle(e.target.value)} placeholder="Title" className="form-control" />
            </td>
            <td className="text-start">
                <div className="input-group">
                    <input type="file" onChange={upload} name="homeBannerImage" className="form-control" />
                </div> 
             {url && 
                <div>
                    <img src={url} width={80} height={50}/>
                </div>
                }
            </td>
            <td >
                <center>
                    <input type="checkbox" name="isProperty" onChange={(e)=>{
                        getPropertyList(e.target.checked)
                        setSelectedProperty({})
                        setSlugUrl("")
                    }} defaultChecked={propertyCheck ? propertyCheck : false} style={{"width":"30px","height":"30px"}} className="form-check-input" />
                </center>
            </td>
            <td >
                    <select className='form-select' disabled={propertyCheck ? false : true} value={selectedProperty.id} onChange={(e)=>handleIsProperty(e.target.value) }>
                        {propertyList === null ? <option disabled>None</option> : 
                        <>
                        <option value="">None</option>
                        {propertyList.map((data)=>(
                        <option key={data.id} value={data.id}>{`${data?.developer?.name} - ${data?.unitNumber}`}</option>
                      ))}
                      </>
                      }
                    </select>
            </td>
            <td className="text-start">
                <input className="form-control" disabled={propertyCheck ? true : false} type="text" name="slug" onChange={(e)=> setSlugUrl(e.target.value)} defaultValue={slugUrl ? slugUrl : ''} />
            </td>
            <td className="text-end">
                <button type="button" onClick={deleteRow} className="btn btn-danger">
                    <i className="fas fa-minus-circle"></i>
                </button>
            </td>
        </tr>
    )
}