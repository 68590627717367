import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";

export default function AddNewRM() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const [microMarketOption,setMicroMarketOption] = useState([])
  useEffect(()=>{
     const optionsMm = process.env.REACT_APP_MICRO_MARKET_KEYS || ""
     if(optionsMm){
       const splitList = optionsMm.split(",")
       if(splitList && splitList.length > 0){
        setMicroMarketOption(splitList)
       }
     }
  },[])
  const addBroker = async (values, event) => {
    const data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      microMarket:values.microMarket
    };

    const update = await JourneyAjax({
      url: `/relation-managers`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": userValidToken || null,
      method: "POST",
      data: data,
      token: true,
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success("RM Added Successfully");
      setTimeout(function() {
        navigate("/admin/property-journey/relation-manager");
      }, 500);
    }
  };
  return (
    <ContentLayout title="Relation Manager">
      <Helmet>
        <title>Add New BHK</title>
      </Helmet>
      <DataView title="Infomation">
        <Formik
          initialValues={{
            name: "",
            email: "",
            mobile: "",
            microMarket:""
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            mobile: Yup.string().required("Mobile Number is required"),
            microMarket:Yup.string().required("Micro market is required")
          })}
          onSubmit={(values, event) => {
            addBroker(values, event);
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <FormGroup label="Name" required>
                  <Field
                    placeholder="Name"
                    name="name"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="name" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Email" required>
                  <Field
                    placeholder="Email"
                    type="email"
                    name="email"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="email" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Mobile" required>
                  <Field
                    placeholder="Mobile Number"
                    type="string"
                    name="mobile"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="mobile" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Micro Market" required>
                  <Field
                    as="select"
                    name="microMarket"
                    className="form-select"
                  >
                  <option value="">Select</option>
                  {microMarketOption && microMarketOption.map((item)=>(
                    <option>{item}</option>
                  ))}
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="microMarket" />
                  </ErrorField>
                </FormGroup>
              </div>
            </div>

            <div className="col-md-2 d-grid">
              <Button type="submit">Add</Button>
            </div>
          </Form>
        </Formik>
      </DataView>
    </ContentLayout>
  );
}
